import React, { useState, useEffect } from "react"
import { FaFileDownload } from "react-icons/fa"

import Layout from "../../components/layout.component"
import Seo from "../../components/seo.component"

const contentful = require("contentful")
const contentfulClient = contentful.createClient({
  space: process.env.GATSBY_CONTENTFUL_SPACE_ID,
  accessToken: process.env.GATSBY_CONTENTFUL_ACCESS_TOKEN,
})

const DownloadPage = (props) => {
  const [file, setFile] = useState(null)

  useEffect(() => {
    contentfulClient
      .getEntries({
        content_type: "link",
        "fields.id": "favorite-75-flute-ensemble-pieces",
      })
      .then(response => {
        setFile(response.items[0])
      })
      .catch(console.error)
  }, [])

  return (
    <Layout className="download">
      <Seo title="File Download" />
      <section>
        <div className="container">
          <div
            className="has-text-centered is-flex is-flex-direction-column is-justify-content-center"
            style={{ minHeight: "50vh" }}
          >
            <h1 className="title has-text-success">Thank you!</h1>
            <h2 className="subtitle">Download your file below and please be sure to save it to your device.</h2>
            {file ? (
              <a
                className="button is-primary"
                href={file.fields.file.fields.file.url}
                style={{ alignSelf: 'center', marginTop: '2rem' }}
                target="_blank"
                rel="noreferrer"
              >
                Download File <FaFileDownload className="ml-4" />
              </a>
            ) : (
              <button
                className="button is-primary"
                disabled
                style={{ alignSelf: 'center', fontFamily: '"Raleway", Arial, Helvetica, sans-serif', marginTop: '2rem' }}
              >
                Download File <FaFileDownload className="ml-4" />
              </button>
            )}
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default DownloadPage
